<template>
  <v-row>
    <VDatePicker
        v-model="currentDate"
        class="col-md-3"
        first-day-of-week="1"
        width="100%"/>

    <v-col class="col-md-9">

      <p v-if="this.$route.params.country.length > 1" class="text-h3">{{ this.$route.params.country }}</p>

      <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
      >
        <v-tab
            v-for="item in tabs"
            :key="item.type"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in tabs"
            :key="item.type"
        >
          <ChartView
              v-model="currentDate"
              :method="fetchData"
              :type="item.type"
          />
        </v-tab-item>
      </v-tabs-items>

    </v-col>

  </v-row>
</template>

<script>
import { getStatistics } from '@/api/endpoints/statistics.js';
import ChartView from '@/components/ChartView.vue';
import tab_items from '@/data/tab_items.js';
import dayjs from 'dayjs';

export default {
  name: 'SaleCountry',

  components: {
    ChartView,
  },

  data: function () {
    return {
      tabs: tab_items,
      tab: null,
      currentDate: new dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    };
  },

  methods: {
    fetchData(type, date) {
      return getStatistics(type, date, undefined, this.$route.params.countryId);
    },
  },
};
</script>
